import { NextPage } from 'next'
import React from 'react'
import SlugPage from './[...slug]'
import Head from 'next/head'  // 导入 Head 组件

const Home: NextPage<{
  showSingIn: (show: boolean) => void
}> = ({ showSingIn }) => {
  return (
    <>
      <Head>
        <title>HeoBiGPT - B站摘要一键生成</title>
      </Head>
      <SlugPage showSingIn={showSingIn} />
    </>
  )
}
export default Home
